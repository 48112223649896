<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">常见问题</div>
        <div class="right"></div>
      </div>
      <div class="section" v-show="questions.length>0">
        <div class="title flex" v-for="(item,index) in questions" :key="index">
          <div class="deng"></div>
          <div class="words">
            <div class="top">{{item.title}}</div>
            <div class="bottom">{{item.content}}</div>
          </div>
        </div>
      </div>
      <van-loading type="spinner" color="#f21313" v-show="questions&&questions.length==0"/>
    </div>
  </div>
</template>
<script>
import {getQuestion} from '@/api/app.js';
export default {
  data(){
    return{
      questions:[],//问题列表
    }
  },
  created(){
    this.getQuestion();
  },
  methods:{
    async getQuestion(){
      let ret = await this.$Api(getQuestion,undefined);
      if(ret.code == 200){
        let list = ret.data.questions?ret.data.questions:[];
        this.questions = list
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  color:#ffffff;
  font-size:14px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:6px 16px 30px;
  box-sizing: border-box;
}
.title{
  margin-top:20px;
  font-size:18px;
  padding-bottom: 10px;
  border-bottom:3px solid #e0e5eb;
}
.deng{
  width:26px;
  height:26px;
  margin:0 4px;
  background:url('../../assets/png/tip.png') center center no-repeat;
  background-size:100%;
  margin-top:-3px;
}
.words{
  flex:1;
}
.top{
  margin-bottom:10px;
}
.bottom{
  padding-top:10px;
  border-top:1px solid #dbe1e8;
  font-size:11px;
  color:#515151;
}
</style>
